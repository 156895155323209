import React from "react";
import { Link } from "gatsby";

import Mixing from "./../images/mixer.svg";
import Microphone from "./../images/microphonewhite.svg";
import Editing from "./../images/scissors.svg";
import Video from "./../images/video.svg";
import JSONData from "../../studio-information.json";
const Pricing = () => {
  return (
    <div>
      <h1
        className="pricing-hours"
        style={{ backgroundColor: "#0000007d", borderRadius: "5px" }}
      >
        <div className="hours">HOURS</div>
        <div className="hours-divider">|</div>
        <div className="hours-time">
          <span>
            {" "}
            {JSONData["hoursStartTime"]}-{JSONData["hoursEndTime"]} PST{" "}
          </span>
          <span style={{ whiteSpace: "nowrap" }}>Every Day</span>
        </div>
      </h1>

      <div className="cards">
        <div className="card">
          <div>
            <div className="title">Tracking</div>
            <div className="icon">
              <Microphone></Microphone>
            </div>
            <div className="features">
              Recording sessions, for either an individual or a group. For full
              day sessions, we operate from {JSONData["hoursFullDayStartTime"]}-
              {JSONData["hoursFullDayEndTime"]} with a{" "}
              {JSONData["hoursFullDayBreakDuration"]} break at{" "}
              {JSONData["hoursFullDayBreakTime"]}.
            </div>
          </div>

          <div>
            <div className="btn">
              Hourly Rate: {JSONData["trackingHourlyRate"]} / hour
            </div>
            <div className="btn">
              Day Rate: {JSONData["trackingDayRate"]} / day
            </div>
          </div>
        </div>
        <div className="card">
          <div>
            <div className="title">Video Shoot</div>
            <div className="icon">
              <Video />
            </div>
            <div className="features">
              For an additional setup fee of {JSONData["videoShootSetupFee"]},
              you can include a video shoot during your recording session.
              Hourly recording costs still apply, even if you only require the
              video service. We also provide video editing services for recorded
              sessions at a rate of {JSONData["videoEditingHourlyRate"]} / hour.
              If you decide to edit with us,{" "}
              {JSONData["videoShootSetupFeeWithEditingCredit"]} of the setup fee
              will be credited towards your editing costs. Visit our{" "}
              <Link className="link" to="./../gallery">
                Gallery&nbsp;Page
              </Link>{" "}
              for sample video sessions.
            </div>
          </div>

          <div className="btn">Setup Fee: {JSONData["videoShootSetupFee"]}</div>
          <div className="btn">
            Editing Rate: {JSONData["videoEditingHourlyRate"]} / hour
          </div>
        </div>
        <div className="card">
          <div>
            <div className="title">Editing</div>
            <div className="icon">
              <Editing />
            </div>
            <div className="features">
              After tracking, we begin the editing process. This often entails
              fixing wrong notes, adjusting timing, and tuning vocals and
              instruments to ensure they are consistently in tune throughout
              each song.
            </div>
          </div>
          <div className="btn">
            Hourly Rate: {JSONData["editingHourlyRate"]} / hour
          </div>
        </div>
        <div className="card">
          <div>
            <div className="title">Mixing</div>
            <div className="icon">
              <Mixing />
            </div>
            <div className="features">
              After editing we begin the mixing process. This often entails
              adding processing such as equalization, compression, and adding
              reverb/delay to each instrument. Each part is also balanced to an
              appropriate level.
            </div>
          </div>
          <div className="btn">
            Hourly Rate: {JSONData["mixingHourlyRate"]} / hour
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
