import React from "react";
import { useState } from "react";

const GoogleMaps = ({}) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = (e) => {
    setIsLoaded((current) => true);
  };

  return (
    <div
      style={{
        display: "grid",
        backgroundColor: "#77777775",
        borderRadius: "4px",
        margin: "-1",
        gridArea: "2 / 1/ 3/ 3",
      }}
    >
      <iframe
        className={
          isLoaded
            ? "google-maps-loaded google-maps"
            : "google-maps-unloaded google-maps"
        }
        style={{ borderRadius: "inherit", border: "0" }}
        onLoad={handleLoad}
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2709.0835797347845!2d-122.31593819999999!3d47.23451100000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5490f8db0f9af60d%3A0xff7092bd3756bec2!2sDavid%20Lange%20Studios!5e0!3m2!1sen!2sus!4v1681315250004!5m2!1sen!2sus"
        width="100%"
        height="600px"
        allowFullScreen=""
        title="Google Maps"
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default GoogleMaps;
