import React from "react";
import Microphone from "../images/microphone5.svg";
import { StaticImage } from "gatsby-plugin-image";

const Hero = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: "30px",
      }}
    >
      <div
        style={{ maxWidth: "700px", display: "flex", flexDirection: "column" }}
      >
        <div className="hero-container">
          <div className="hero-microphone">
            <Microphone className="hero-microphone-vector" />
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="hero-text">Welcome to</div>
            <h1 style={{ paddingTop: 0 }} className="hero-text">
              Lange Studios
            </h1>
          </div>
        </div>
        <hr
          className="hero-divider"
          style={{
            height: "8px",
            width: "100%",

            backgroundColor: "#05655f",

            borderRadius: "12px",

            border: "0px solid transparent",
          }}
        />
        <h2>
          For over 40 years, Lange Studios has been committed to delivering
          first-rate recordings to our clients. We believe it takes both the
          highest quality equipment and a comfortable environment to produce and
          capture the very best of our musicians
        </h2>
      </div>
      <div className="hero-secondary">
        {/* <Microphone style={{ maxHeight: "500px" }}></Microphone> */}
        <StaticImage
          style={{
            maxHeight: "500px",
            maxWidth: "325px",
            boxShadow: "0 0 20px #2d2214",
            marginRight: "30px",
          }}
          src="../images/jacqueline_home.jpg"
          alt=""
        />
      </div>
    </div>
  );
};

export default Hero;
