import React from "react";
import { Button } from "@mui/material";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationIcon from "@mui/icons-material/LocationOn";
import "./../pages/global.scss";
import GoogleMaps from "./GoogleMaps";

const ContactInfo = ({ buttonColor = "red", buttonHoverColor = "yellow" }) => {
  const buttonStyle = {
    textTransform: "none",
    backgroundColor: buttonColor,
    fontSize: "1.5em",
    maxHeight: "150px",
    display: "flex",
    flexWrap: "wrap",
    gap: "10px",
    flexDirection: { xs: "column", sm: "row" },
    height: { xs: "120px", sm: "100px" },
    width: "100%",
    color: "white",
    width: "100%",
    "&:hover": {
      backgroundColor: buttonHoverColor,
    },
  };
  return (
    <div className="contact-container">
      <div className="contact-button-container">
        <a
          href="mailto: langerecordingstudios@gmail.com"
          style={{ width: "100%" }}
          tabIndex="-1"
        >
          <Button className="contact-button" sx={buttonStyle}>
            <EmailIcon sx={{ transform: "scale(1.5)" }} alt="Email Icon" />
            <address className="contact-button-text-parent">
              <span className="contact-button-text">langerecordingstudios</span>
              <span className="contact-button-text">@gmail.com</span>
            </address>
          </Button>
        </a>
        <a href="Tel: 253-922-3496" style={{ width: "100%" }} tabIndex="-1">
          <Button className="contact-button" sx={buttonStyle}>
            <PhoneIcon sx={{ transform: "scale(1.5)" }} alt="Phone Icon" />

            <address className="contact-button-text">(253) 922-3496</address>
          </Button>
        </a>
      </div>
      <GoogleMaps />
      <div className="link-parent" id="bottom-link-parent">
        <a
          href="https://www.google.com/maps/place/David+Lange+Studios,+8514+25th+St+E,+Edgewood,+WA+98371/@47.234511,-122.315938,17z/data=!4m6!3m5!1s0x5490f8db0f9af60d:0xff7092bd3756bec2!8m2!3d47.234511!4d-122.3159382!16s%2Fg%2F11c45yhr_c?hl=en&gl=US"
          className="icon-link link-google-maps"
        >
          <LocationIcon
            alt="Google Maps Icon"
            className="social-media-icon youtube-icon2"
            style={{ minWidth: "48px", minHeight: "48px" }}
          />
          <div>
            <div>View on Google Maps</div>{" "}
            <address>8514 25th St E, Edgewood, WA 98371</address>
          </div>
        </a>
      </div>
    </div>
  );
};
export default ContactInfo;
