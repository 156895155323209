import React from "react";

import { StaticImage } from "gatsby-plugin-image";

const BackgroundImage = () => {
  return (
    <div
      className="background-container"
      style={{
        gridArea: "1 / 1 / 2 / 2",
        display: "flex",
        maxHeight: "400px",

        backgroundColor: "black",

        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="background-parent-mobile">
        <StaticImage
          className="background-image fade-image"
          src="./../images/layout/mobile-exterior.jpg"
          alt=""
          placeholder="none"
          layout="fullWidth"
          loading="eager"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
      <div className="background-parent-desktop">
        <StaticImage
          className="background-image fade-image"
          src="./../images/layout/exterior-2.jpg"
          loading="eager"
          placeholder="none"
          layout="fullWidth"
          backgroundColor="#51977B"
          alt=""
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    </div>
  );
};

export default BackgroundImage;
