import React from "react";
import Section from "../components/Section";

import Pricing from "../components/Pricing";
import ContactInfo from "../components/ContactInfo";

import LocationIcon from "@mui/icons-material/LocationOn";

import Hero from "../components/Hero";

import Sample from "../components/Sample";
import SampleContainer from "../components/SampleContainer";

import { Link } from "gatsby";

import "./global.scss";
import { HOME } from "./../layouts/colors";

import { graphql } from "gatsby";

import { GatsbyImage, getImage } from "gatsby-plugin-image";
//import SampleSelector from "../components/SampleSelector";

import Box from "@mui/material/Box";
import { MenuItem } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import ExpandMoreIcon from "../images/expandIcon.svg";
import { StaticImage } from "gatsby-plugin-image";

import sampleData from "../../samples.json";

const sampleAudioDirectory = "music/";

const IndexPage = ({ data }) => {
  const SampleSelector = () => {
    function TabPanel(props) {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`full-width-tabpanel-${index}`}
          aria-labelledby={`full-width-tab-${index}`}
          {...other}
        >
          {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
      );
    }

    function getFileName(title) {
      return title.toLowerCase().replace(/ /g, "_");
    }

    const iconComponent = (props) => {
      return (
        <ExpandMoreIcon
          style={{ marginTop: "-15px" }}
          className={props.className + " "}
        />
      );
    };

    const sections = Object.keys(sampleData);

    const [playlist, setPlaylist] = React.useState(0);

    const handleChange = (event) => {
      setPlaylist(event.target.value);
    };

    return (
      <Box style={{ marginTop: "10px" }}>
        <FormControl fullWidth hiddenLabel={true}>
          <InputLabel id="demo-simple-select-label"></InputLabel>
          <Select
            MenuProps={{
              disableScrollLockX: true,
            }}
            IconComponent={iconComponent}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={playlist}
            label=""
            onChange={handleChange}
            sx={{
              color: "white", //"white",
              alignContent: "center",
              backgroundColor: "rgba(148, 148, 148, 0.368627451)", // "white", //"#282828",
              borderRadius: "12px",
              borderStyle: "none",
              boxShadow: "none",

              ".MuiOutlinedInput-notchedOutline": { border: 0 },

              border: "2px solid",
              borderColor: " rgba(255, 255, 255, 0)",
              transition: "all 250ms ease",
              "&:hover": {
                borderColor: "#ffffffff",
              },
              "&:focus-visible": {
                borderColor: "white",
                transition: "all 250ms ease",
              },

              " .MuiOutlinedInput-notchedOutline": {
                borderColor: "transparent",
              },

              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderRadius: "12px",
                borderColor: "white",
                margin: "-2px",
              },
            }}
          >
            {sections.map((section, index) => {
              return <MenuItem value={index}>{section}</MenuItem>;
            })}
          </Select>
        </FormControl>
        <div style={{ height: "20px" }}></div>

        <div>
          {sections.map((section, index) => {
            return (
              <TabPanel value={playlist} index={index}>
                <SampleContainer>
                  {sampleData[section].map((song) => {
                    let sampleFileName = getFileName(song.title);
                    return (
                      <Sample
                        fileName={
                          sampleAudioDirectory + sampleFileName + ".mp3"
                        }
                        trackName={song.title}
                        albumName={song.album}
                        artistName={song.artist}
                        color="
                        #01302e"
                      >
                        <GatsbyImage
                          style={{
                            height: "100%",
                            width: "100%",
                          }}
                          alt=""
                          // alt={song.album}
                          objectFit="cover"
                          image={getImage(getSampleImage(sampleFileName).node)}
                        ></GatsbyImage>
                      </Sample>
                    );
                  })}
                </SampleContainer>
              </TabPanel>
            );
          })}
        </div>
      </Box>
    );
  };

  function getSampleImage(name) {
    return data.allFile.edges.find(
      (item) =>
        item.node.childImageSharp.fluid.src.split("/").pop().split(".")[0] ===
        name
    );
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Section tabColor={HOME.tab} bodyColor={HOME.body2}>
        <Hero></Hero>
        <div className="timeline-image-mobile">
          <StaticImage
            src="./../images/mobile-hero.jpg"
            alt=""
            className="timeline-image-child"
            placeholder="dominantColor"
          ></StaticImage>
        </div>
      </Section>

      <Section
        bodyColor={HOME.body}
        tabColor={HOME.tab}
        showTab={false}
        style={{
          gridArea: "1/1/ 2 / 2",
          gridTemplateRows: "1fr",
          gridTemplateColumns: "1fr",
        }}
        id="tab-body"
        caption="Hear samples from artists recorded & mixed at Lange Studios"
        title="Client Samples"
      >
        <div className="link-parent">
          <a
            rel="noreferrer"
            href="https://open.spotify.com/user/31bzul4uimrdoczmaxmh43h4h3vi"
            className="icon-link link-spotify"
          >
            <svg
              className="social-media-icon spotify-icon2"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ minWidth: "48px", minHeight: "48px" }}
            >
              <path
                fill="currentColor"
                d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75c-.5.15-1-.15-1.15-.6c-.15-.5.15-1 .6-1.15c3.55-1.05 9.4-.85 13.1 1.35c.45.25.6.85.35 1.3c-.25.35-.85.5-1.3.25m-.1 2.8c-.25.35-.7.5-1.05.25c-2.7-1.65-6.8-2.15-9.95-1.15c-.4.1-.85-.1-.95-.5c-.1-.4.1-.85.5-.95c3.65-1.1 8.15-.55 11.25 1.35c.3.15.45.65.2 1m-1.2 2.75c-.2.3-.55.4-.85.2c-2.35-1.45-5.3-1.75-8.8-.95c-.35.1-.65-.15-.75-.45c-.1-.35.15-.65.45-.75c3.8-.85 7.1-.5 9.7 1.1c.35.15.4.55.25.85M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
              />
            </svg>
            <div>Visit our Spotify profile to hear more from our clients</div>
          </a>
        </div>
        <div
          className="h3-wrapper"
          style={{
            width: "100%",
            marginBottom: "0px",
            marginTop: "10px",
            maxWidth: "600px",
          }}
        >
          <div className="bio-rectangle-parent">
            <div className="bio-rectangle"></div>
            <div className="bio-rectangle-two"></div>
          </div>
          <h3
            style={{
              padding: "0px",
            }}
          >
            Select a genre from the dropdown menu below to view its samples
          </h3>
        </div>
        <SampleSelector></SampleSelector>

        <div className="link-parent" id="bottom-link-parent">
          <a
            rel="noreferrer"
            href="https://open.spotify.com/user/31bzul4uimrdoczmaxmh43h4h3vi"
            className="icon-link link-spotify"
          >
            <svg
              className="social-media-icon spotify-icon2"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              style={{ minWidth: "48px", minHeight: "48px" }}
            >
              <path
                fill="currentColor"
                d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75c-.5.15-1-.15-1.15-.6c-.15-.5.15-1 .6-1.15c3.55-1.05 9.4-.85 13.1 1.35c.45.25.6.85.35 1.3c-.25.35-.85.5-1.3.25m-.1 2.8c-.25.35-.7.5-1.05.25c-2.7-1.65-6.8-2.15-9.95-1.15c-.4.1-.85-.1-.95-.5c-.1-.4.1-.85.5-.95c3.65-1.1 8.15-.55 11.25 1.35c.3.15.45.65.2 1m-1.2 2.75c-.2.3-.55.4-.85.2c-2.35-1.45-5.3-1.75-8.8-.95c-.35.1-.65-.15-.75-.45c-.1-.35.15-.65.45-.75c3.8-.85 7.1-.5 9.7 1.1c.35.15.4.55.25.85M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
              />
            </svg>
            <div>Visit our Spotify profile to hear more from our clients</div>
          </a>
        </div>
      </Section>
      <div style={{ display: "grid", gridArea: "1 /1 /2 /2" }}></div>
      <Section
        bodyColor={HOME.body2}
        tabColor={HOME.tab}
        showTab={false}
        className="tab-body"
        title="Pricing"
        caption="See our rates for the services we provide"
      >
        <div className="h3-wrapper">
          <div className="bio-rectangle-parent">
            <div className="bio-rectangle"></div>
            <div className="bio-rectangle-two"></div>
          </div>
          <h3>
            Visit our{" "}
            <Link className="link" to="./../recording-process">
              Recording Process Page
            </Link>{" "}
            for more information on each of the services priced below
          </h3>
        </div>
        <div className="home-pricing-container">
          <Pricing></Pricing>
        </div>
      </Section>
      <Section
        bodyColor={HOME.body}
        tabColor={HOME.tab}
        style={{ color: "#9eb3ff", textDecoration: "underline" }}
        showTab={false}
        className="tab-body"
        title="Contact Us"
        caption="Looking to book a session? Questions? Reach out to us with any inquiries"
      >
        <div className="link-parent">
          <a
            style={{}}
            rel="noreferrer"
            href="https://www.google.com/maps/place/David+Lange+Studios,+8514+25th+St+E,+Edgewood,+WA+98371/@47.234511,-122.315938,17z/data=!4m6!3m5!1s0x5490f8db0f9af60d:0xff7092bd3756bec2!8m2!3d47.234511!4d-122.3159382!16s%2Fg%2F11c45yhr_c?hl=en&gl=US"
            className="icon-link link-google-maps"
          >
            <LocationIcon
              className="social-media-icon youtube-icon2"
              style={{ minWidth: "48px", minHeight: "48px" }}
            />
            <div>
              <div>View on Google Maps</div>
              <address>8514 25th St E, Edgewood, WA 98371</address>
            </div>
          </a>
        </div>
        <div className="h3-wrapper">
          <div className="bio-rectangle-parent">
            <div className="bio-rectangle"></div>
            <div className="bio-rectangle-two"></div>
          </div>

          <h3>
            Visit our{" "}
            <Link className="link" to="./../recording-process">
              Recording Process Page
            </Link>{" "}
            for information on our recording process and frequently asked
            questions
          </h3>
        </div>
        {/* <h3>Email: langerecordingstudios@gmail.com</h3>
        <h3>Phone: (253) 922-3496</h3>
        <h3>Address: 8514 25th St E, Edgewood, WA 98371 </h3> */}
        <ContactInfo
          buttonColor={"rgb(0, 119, 113)"}
          buttonHoverColor="#01282a"
        />
      </Section>
    </div>
  );
};

export default IndexPage;

export const Head = () => (
  <>
    <html lang="en" />
    <meta name="author" content="Justin Haddock" />
    <meta name="copyright" content="Lange Studios" />

    <meta name="title" content="Home" />
    <meta name="theme-color" content={HOME.mobile} />
    <title>Home | Lange Studios</title>
    <meta
      name="keywords"
      content="Lange Studios, recording studio, recording, recording process, client samples, samples, pricing, contact, client samples"
    />
    <meta name="description" content="Home page for Lange Studios"></meta>
  </>
);

export const query = graphql`
  query HomePageQuery {
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(jpeg)|(png)/" }
        relativeDirectory: { eq: "sample" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
