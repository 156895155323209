import React from "react";
import Pause from "./../images/pause-round-icon.svg";
import Play from "./../images/play-round-icon.svg";
import AudioPlayer from "react-h5-audio-player";
import { createRef } from "react";
import "react-h5-audio-player/lib/styles.css";

// import { StaticImage } from "gatsby-plugin-image";
const SampleContainer = ({ children }) => {
  return <div className="sample-container">{children}</div>;
};

export default SampleContainer;
