import React from "react";
import Pause from "./../images/pause-round-icon.svg";
import Play from "./../images/play-round-icon.svg";
import AudioPlayer from "react-h5-audio-player";
import { createRef } from "react";
import { useState } from "react";
import "react-h5-audio-player/lib/styles.css";

const Sample = ({
  children,
  trackName,
  artistName,
  imageName,
  color = "black",
  albumName,
  fileName,
  genre,
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleLoad = (e) => {
    setIsLoaded((current) => true);
  };
  const overlay = <div className="sample-image-overlay"></div>;

  let player = createRef();

  function pauseAllAudioSources(e) {
    setIsPlaying(true);

    document.querySelectorAll("audio").forEach((el) => {
      if (el !== player.current.audio.current) {
        el.pause();
      }
    });
  }

  return (
    <div
      style={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <div className="sample-genre">{genre}</div>
      <div onLoad={handleLoad} style={{ display: "grid" }}>
        <div
          style={{
            gridArea: "1 / 1 / 2 /2",
            width: "100%",
            height: "100%",
            backgroundColor: "gray",

            borderRadius: "12px",
          }}
        ></div>
        <div
          style={{ gridArea: "1 / 1 / 2 /2", backgroundColor: color }}
          className={
            isLoaded
              ? "sample-card sample-card-unloaded"
              : "sample-card sample-card-loaded"
          }
        >
          <div
            className="sample-image"
            onClick={() => {
              if (isPlaying) {
                player.current.audio.current.pause();
                setIsPlaying(false);
              } else {
                player.current.audio.current.play();
                setIsPlaying(true);
              }
            }}
          >
            <div className="sample-image-image">{children}</div>
            <div
              className={
                isPlaying
                  ? "sample-image-overlay playing"
                  : "sample-image-overlay"
              }
            >
              <div className="sample-image-overlay-button">
                {isPlaying ? <Pause /> : <Play />}
              </div>
              {/* <div
                className={
                  isPlaying
                    ? "sample-image-overlay-button"
                    : "sample-image-overlay-button-hidden"
                }
              >
                <Pause></Pause>
              </div> */}
            </div>
            <div className="sample-info" style={{ backgroundColor: color }}>
              <div className="sample-info-title">{trackName}</div>
              {/* <div className="sample-info-album">{albumName}</div> */}
              <div className="sample-info-artist">{artistName}</div>
              {/* <audio
            controls
            controlsList="nodownload noplaybackrate"
            preload="none"
            id={fileName}
            style={{}}
            onPlay={pauseAllAudioSources}
            onPause={pause}
          >
            <source src={"../../" + fileName} type="audio/mpeg" />
          </audio> */}
              <AudioPlayer
                preload="metadata"
                customControlsSection={["MAIN_CONTROLS", "VOLUME_CONTROLS"]}
                // customAdditionalControls={[]}
                ref={player}
                id={fileName}
                showJumpControls={false}
                showFilledVolume={false}
                show
                src={"../../" + fileName}
                onPlay={pauseAllAudioSources}
                onPause={() => {
                  setIsPlaying(false);
                }}

                // other props here
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sample;
