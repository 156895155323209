import React from "react";
import { Container } from "@mui/system";
import { Box } from "@mui/system";
import "./../pages/global.scss";

function getTabHeight(value) {
  if (value) {
    return "38px";
  }
  return "0px";
}

function displayTab(value) {
  if (value) {
    return "block";
  }
  return "none";
}

const Section = ({
  title,
  titleColor = "white",
  textColor = "white",
  tabColor = "darkgreen",
  bodyColor = "green",
  caption,
  hideHeaderMobile = false,
  showTab = true,
  background,
  className = "tab-default",

  children,
}) => {
  return (
    <section style={{ backgroundColor: bodyColor, paddingBottom: "15px" }}>
      <div style={{ display: displayTab(showTab) }}>
        <Box
          variant="outlined"
          elevation={3}
          className={"tab " + className}
          sx={{
            borderRadius: "0px",
            backgroundColor: tabColor,
            color: titleColor,
            position: "relative",

            boxShadow: "0 0 10px rgba(0, 0, 0, 0.428);",
          }}
        >
          <Container
            className="testa"
            sx={{ height: getTabHeight(showTab) }}
          ></Container>
        </Box>
      </div>
      <div
        className="test-background"
        style={{
          backgroundColor: bodyColor,
          display: "grid",
          color: textColor,
          zIndex: 1,
        }}
      >
        <Container sx={{ gridArea: "1/1/2/2", zIndex: 1 }}>
          {title ? (
            <h1
              className={
                hideHeaderMobile
                  ? "section-header hide-mobile"
                  : "section-header"
              }
              style={{
                margin: 0,

                zIndex: 2,
              }}
            >
              {title}
            </h1>
          ) : (
            <div
              className={
                hideHeaderMobile
                  ? "section-header hide-mobile"
                  : "section-header"
              }
              style={{
                margin: 0,
                paddingTop: "15px",
              }}
            ></div>
          )}

          {caption ? (
            <h2 className="section-caption" style={{ maxWidth: "600px" }}>
              {caption}
            </h2>
          ) : (
            <div className="section-caption"></div>
          )}

          {/* <div
            style={{
              width: "100%",
              opacity: "50%",
              backgroundColor: "white",
              height: "5px",
              maxWidth: "600px",
              marginBottom: "10px",
            }}
          ></div> */}

          {children}
        </Container>
      </div>
    </section>
  );
};

export default Section;
