import React, { useLayoutEffect } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";

import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { useRef } from "react";

import Button from "@mui/material/Button";

import { CssBaseline } from "@mui/material";
// import AniLink from "gatsby-plugin-transition-link/AniLink";
import { Link } from "gatsby";

const theme = createTheme({
  breakpoints: {
    values: {
      // Define your custom breakpoint sizes here
      xs: 0, // Extra-small devices (portrait phones)
      sm: 851, // Small devices (landscape phones)
      md: 960, // Medium devices (tablets)
      lg: 1280, // Large devices (desktops)
      xl: 1920, // Extra-large devices (large desktops)
      // You can add more custom breakpoints if needed
      xxl: 2560, // Extra-extra-large devices
    },
  },
});

const drawerWidth = "60%";
const navItems = [
  "Home",
  "Client Samples",
  "Recording Process",
  "Pricing",

  "Equipment",
  "Contact",

  "Gallery",

  "About",
];

// function getSidebarColor(color) {
//   if (color === "#88bca4") {
//     return "rgb(24,132,100)";
//   }
//   return color;
// }

function DrawerAppBar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const pageButtonContainer = useRef();
  const mobilePageButtonContainer = useRef();

  // useLayoutEffect(() => {
  //   const currentPage = props.location.pathname.substring(
  //     1,
  //     props.location.pathname.length - 1
  //   );

  //   if (
  //     pageButtonContainer &&
  //     pageButtonContainer.current.children &&
  //     mobilePageButtonContainer
  //   ) {
  //     Array.from(pageButtonContainer.current.children).forEach((item) => {
  //       if (
  //         item.id === "nav-item-" + currentPage ||
  //         (props.location.pathname === "/" && item.id === "nav-item-home")
  //       ) {
  //         item.className = "nav-button-container active";
  //       } else {
  //         item.className = "nav-button-container";
  //       }
  //     });
  //   }
  // }, [props.location]);

  useEffect(() => {
    const currentPage = props.location.pathname.substring(
      1,
      props.location.pathname.length - 1
    );

    if (
      pageButtonContainer &&
      pageButtonContainer.current.children &&
      mobilePageButtonContainer
    ) {
      Array.from(pageButtonContainer.current.children).forEach((item) => {
        if (
          item.id === "nav-item-" + currentPage ||
          (props.location.pathname === "/" && item.id === "nav-item-home")
        ) {
          item.classList.add("active");
        } else {
          item.className = "nav-button-container";
        }
      });

      Array.from(mobilePageButtonContainer.current.children).forEach((item) => {
        if (
          item.id === "mobile-page-link-" + currentPage ||
          (props.location.pathname === "/" &&
            item.id === "mobile-page-link-home")
        ) {
          item.className = "mobile-page-link active";
        } else {
          item.className = "mobile-page-link";
        }
      });
    }
  }, [props.location]);

  const navBarStyles = {
    width: "100%",
    minHeight: "68px",
    maxHeight: "68px",
    color: "#fff",

    borderRadius: 0,
    padding: "0px",
    height: "100%",
  };

  const drawerContainerStyles = {
    display: { xs: "block", sm: "none" },
    "& .MuiDrawer-paper": {
      boxSizing: "content-box",
      width: drawerWidth,
      minWidth: "280px",
      height: "100%",

      backgroundColor: "#076060", //getSidebarColor(props.tabColor),
    },
  };

  const mobileDrawerButtonStyles = {
    textAlign: "center",
    paddingLeft: "10px",
    // borderLeft: "15px solid #014a4a",
    //backgroundColor: "#076060",
    fontFamily: "Comfortaa, Arial, Helvetica, sans-serif",

    fontSize: "1.2em",

    color: "white",
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const mapNavigation = (item) => {
    if (item === "Home") {
      return "/";
    }
    if (item === "Client Samples") {
      return "/client-samples";
    }
    if (item === "Recording Process") {
      return "/recording-process";
    }
    return `/${item.toLowerCase()}`;
  };
  // const alternateColor = (index) => {
  //   if (index % 2 === 0) {
  //     return "#3e3b38";
  //   }
  //   return "#58534e";
  // };

  // const alternateColorTheme = (index) => {
  //   if (index % 2 === 0) {
  //     return "#000000c7";
  //   }
  //   return "#00000083";
  // };

  function navigationLabel(item) {
    if (item === "Recording Process") {
      return (
        <div className="nav-text" key={item}>
          <div>Recording</div>
          <div>Process</div>
        </div>
      );
    }

    if (item === "Client Samples") {
      return (
        <div className="nav-text" key={item}>
          <div>Client</div>
          <div>Samples</div>
        </div>
      );
    }
    return (
      <div className="nav-text" key={item}>
        {item}
      </div>
    );
  }

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{
        flexDirection: "column",
        display: "flex",
        textAlign: "start",
        backgroundColor: "inherit",

        justifyContent: "flex-end",
      }}
    >
      <div
        style={{
          my: 2,
          color: "white",
          textAlign: "center",
          padding: "10px",
          margin: "0px",
        }}
      >
        {/* <StaticImage src="../images/LangeIcon.png" /> */}
        {/* <div style={{ aspectRatio: "1 / 1", width: "100%" }}></div> */}
        {/* <LangeIcon
          style={{
            aspectRatio: "1 / 1",

            paddingTop: "10px",
            paddingBottom: "10px",
            // paddingRight: "10px",
            // paddingLeft: "10px",
          }}
        >
          
        </LangeIcon> */}
        <div className="menu-spacer"></div>
      </div>
      <Divider />
      <List
        ref={mobilePageButtonContainer}
        sx={{
          "&>*:nth-child(even)": { backgroundColor: "#065353" },
          "&>*:nth-child(odd)": {},

          display: "flex",
          flexDirection: "column",
          // gap: "2px ",

          backgroundColor: "#0d7474",
          padding: "0px",
          // borderTop: "2px solid #053e3e",
          // borderBottom: "2px solid #053e3e",
        }}
      >
        {navItems.map((item, index) => (
          <Link
            duration={0}
            to={mapNavigation(item)}
            key={index}
            className="mobile-page-link"
            id={"mobile-page-link-" + item.replace(/\s+/g, "-").toLowerCase()}
          >
            <ListItem key={item} disablePadding>
              <ListItemButton
                className="nav-button-mobile"
                key={item}
                disableRipple
                sx={mobileDrawerButtonStyles}
              >
                <div className="page-button-text-mobile" key={item}>
                  {item}
                </div>
              </ListItemButton>
            </ListItem>
            {<hr className="navbar-mobile-divider"></hr>}
          </Link>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <ThemeProvider theme={theme}>
      <Box
        className="navbar-box"
        sx={{
          maxHeight: "68px",

          backgroundColor: { xs: props.tabColor, sm: "#1f1f1f" },
        }}
      >
        <CssBaseline />
        <AppBar
          position="sticky"
          component="nav"
          sx={{
            paddingRight: " 0 !important",
            alignSelf: "flex-start",
            position: "-webkit-sticky",
            position: "sticky",
            display: "block",
            maxHeight: "68px",

            backgroundColor: "inherit",
          }}
        >
          <Toolbar
            sx={{
              alignSelf: "flex-start",
              display: "flex",
              width: "100%",
              paddingLeft: { xs: "0px", sm: "0" },
              paddingRight: { xs: "0px", sm: "0" },
              justifyContent: { xs: "flex-start", sm: "center" },
            }}
          >
            <Button
              preserveAspectRatio="none"
              style={{}}
              disableRipple
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                // marginLeft: "-25%",
                paddingLeft: "25px",
                display: { xs: "flex", sm: "none" },
                justifyContent: "flex-start",
                color: "white",
                gap: "35px",
                width: "100%",
                height: "100%",
              }}
            >
              <div
                style={{
                  scale: "2",
                  filter: "drop-shadow(0 0 0.75rem black)",
                  display: "flex",
                }}
              >
                <MenuIcon />
              </div>
              <span
                style={{
                  fontSize: "1.9em",
                }}
              >
                Menu
              </span>
            </Button>
            <Box
              className="nav-box"
              ref={pageButtonContainer}
              sx={{
                display: { xs: "none", sm: "grid" },
                height: "100%",
                // backgroundColor: props.tabColor,
                width: "100%",

                maxWidth: "1920px",
                justifyItems: "center",
                // borderTop: "3px solid #1f1f1f",
                // borderBottom: "3px solid #1f1f1f",
                gridAutoFlow: "column",
              }}
            >
              {navItems.map((item) => (
                <div
                  // className="nav-button-container"
                  id={"nav-item-" + item.replace(/\s+/g, "-").toLowerCase()}
                  className="nav-button-container initial"
                >
                  <Link
                    to={mapNavigation(item)}
                    className="nav-button-link"
                    tabIndex="-1"
                  >
                    <Button
                      key={item}
                      className="navItem"
                      style={{
                        ...navBarStyles,
                      }}
                    >
                      <ListItemText
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <div className="page-button-text">
                          {navigationLabel(item)}
                        </div>
                      </ListItemText>
                    </Button>
                  </Link>
                </div>
              ))}
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav" className="color-change">
          <div style={{}}>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              className="color-change"
              sx={drawerContainerStyles}
            >
              {drawer}
            </Drawer>
          </div>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default DrawerAppBar;
