import React, { useEffect } from "react";
import Icon from "../images/LangeStudiosLogo6.svg";

const LangeStudiosLogo = () => {
  // useEffect(() => {
  //   classList = classList + " fade-in";
  //   document.querySelector("#change-class").classList.add("fade-in");
  // }, []);

  return (
    <Icon
      className="lange-studios-logo fade-in"
      id="change-class"
      alt="Lange Studios"
    />
  );
};

export default LangeStudiosLogo;
