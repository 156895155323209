export const HOME = {
  title: "",
  body: "#014340",
  tab: "#06746d",
  body2: "#01524e",
  overlay: "#05655F60",
  mobile: "#06746d",
};

export const PRICING = {
  title: "Pricing",
  body: "#10324f",
  tab: "#365A7C",
  overlay: "#4c7fae60",
  mobile: "#365A7C",
};

export const RECORDING_PROCESS = {
  title: "Recording Process",
  body: "#4b403a",
  body2: "#3d342e",
  tab: "#a39385",
  overlay: "#a6815e60",
  mobile: "#7e7368",
};

export const CLIENT_SAMPLES = {
  title: "Client Samples",
  body: "#3d0f28",
  tab: "#582648",
  overlay: "#58264860",
  mobile: "#582648",
};

export const CONTACT = {
  title: "Contact",
  body: "#07494b",
  body2: "#095c5f",
  button: "#00898d",
  tab: "#569799",
  overlay: "#56979960",
  mobile: "#0f7881",
};

export const EQUIPMENT = {
  title: "Equipment",
  body: "#34373D",
  tab: "#6d748b",
  overlay: "#aabcd360",
  mobile: "#6d748b",
};

export const GALLERY = {
  title: "Gallery",

  body: "#4f473a",
  body2: "#423b30",
  tab: "#a39378",
  overlay: "#f8e0a960",
  mobile: "#83704f",
};

export const ABOUT = {
  title: "About",
  body: "#19664f",
  body2: "#155643",
  tab: "#3da080",
  overlay: "#88bca460",
  mobile: "#3da080",
};

export const ERROR = {
  title: "404",
  body: "#7c4055",
  tab: "#dfb6c4",
  overlay: "#dfb6c460",
  mobile: "#dfb6c4",
};
