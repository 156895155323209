import React from "react";

import Box from "@mui/material/Box";

import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import LocationIcon from "@mui/icons-material/LocationOn";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";

function Footer() {
  //const { window } = props;

  // const alternateColor = (index) => {
  //   if (index % 2 === 0) {
  //     return "black";
  //   }
  //   return "#041014";
  // };

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  return (
    <div style={{ overflow: "hidden" }}>
      <Box
        className="footer-box"
        sx={{
          display: "flex",
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "50px",
          paddingTop: "10px",

          paddingBottom: "10px",

          paddingLeft: "20px",
          paddingRight: "20px",

          backgroundColor: "#1f1f1f",
        }}
      >
        <div
          className="footer-parent"
          style={{
            display: "flex",

            width: "100%",
            maxWidth: "1920px",
          }}
        >
          <div>
            <span className="copyright-desktop">© Lange Studios 2023</span>
            <span className="creator-link-desktop">
              &nbsp;|&nbsp;
              <a
                rel="noreferrer"
                href="https://justinhaddock.dev/"
                style={{ color: "white", textDecoration: "underline" }}
              >
                Website by Justin Haddock
              </a>
            </span>
          </div>
          <div className="right-elements">
            <a
              rel="noreferrer"
              href="mailto: langerecordingstudios@gmail.com"
              className="footer-link"
              id="link-email"
            >
              <EmailIcon className="social-media-icon email-icon" />
              <address>langerecordingstudios@gmail.com</address>
            </a>

            <a
              rel="noreferrer"
              href="Tel: 253-922-3496"
              className="footer-link"
              id="link-phone"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <PhoneIcon className="social-media-icon phone-icon" />
              <address>(253) 922-3496</address>
            </a>
            <a
              rel="noreferrer"
              href="https://www.google.com/maps/place/David+Lange+Studios,+8514+25th+St+E,+Edgewood,+WA+98371/@47.234511,-122.315938,17z/data=!4m6!3m5!1s0x5490f8db0f9af60d:0xff7092bd3756bec2!8m2!3d47.234511!4d-122.3159382!16s%2Fg%2F11c45yhr_c?hl=en&gl=US"
              className="footer-link"
              id="link-maps"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <LocationIcon className="social-media-icon maps-icon" />
              <div>Google Maps</div>
              {/* <div className="maps-mobile">
                8514 25th St E, Edgewood, WA 98371
              </div> */}
            </a>

            <a
              rel="noreferrer"
              href="https://www.facebook.com/DavidLangeStudios/"
              className="footer-link"
              id="link-facebook"
            >
              <FacebookIcon className="social-media-icon facebook-icon" />
              <div>Facebook</div>
            </a>

            <a
              rel="noreferrer"
              href="https://www.youtube.com/@deedpartle"
              className="footer-link"
              id="link-youtube"
            >
              <YouTubeIcon className="social-media-icon youtube-icon" />
              <div>YouTube</div>
            </a>

            {/* <a
              rel="noreferrer"
              href="https://open.spotify.com/user/31bzul4uimrdoczmaxmh43h4h3vi"
              className="footer-link"
              id="link-spotify"
            >
              <svg
                className="social-media-icon spotify-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M17.9 10.9C14.7 9 9.35 8.8 6.3 9.75c-.5.15-1-.15-1.15-.6c-.15-.5.15-1 .6-1.15c3.55-1.05 9.4-.85 13.1 1.35c.45.25.6.85.35 1.3c-.25.35-.85.5-1.3.25m-.1 2.8c-.25.35-.7.5-1.05.25c-2.7-1.65-6.8-2.15-9.95-1.15c-.4.1-.85-.1-.95-.5c-.1-.4.1-.85.5-.95c3.65-1.1 8.15-.55 11.25 1.35c.3.15.45.65.2 1m-1.2 2.75c-.2.3-.55.4-.85.2c-2.35-1.45-5.3-1.75-8.8-.95c-.35.1-.65-.15-.75-.45c-.1-.35.15-.65.45-.75c3.8-.85 7.1-.5 9.7 1.1c.35.15.4.55.25.85M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2Z"
                />
              </svg>
              <div>Spotify</div>
            </a> */}
            <div className="copyright"> © Lange Studios 2023</div>
            <div className="creator-link-mobile">
              {" "}
              <a
                rel="noreferrer"
                href="https://justinhaddock.dev/"
                style={{ color: "white", textDecoration: "underline" }}
              >
                Website by Justin Haddock
              </a>
            </div>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default Footer;
