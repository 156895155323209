import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BackgroundImage from "../components/BackgroundImage";

import LangeStudiosLogo from "../components/LangeStudiosLogo";

import "../pages/global.scss";
import "../pages";
import {
  HOME,
  CLIENT_SAMPLES,
  RECORDING_PROCESS,
  PRICING,
  CONTACT,
  EQUIPMENT,
  GALLERY,
  ABOUT,
  ERROR,
} from "./colors";

export function Head() {
  return (
    <>
      <html lang="en" />
    </>
  );
}

function getPageInfo(path) {
  switch (path) {
    case "/":
      return HOME;
    case "/client-samples/":
      return CLIENT_SAMPLES;
    case "/recording-process/":
      return RECORDING_PROCESS;
    case "/pricing/":
      return PRICING;
    case "/contact/":
      return CONTACT;
    case "/equipment/":
      return EQUIPMENT;
    case "/gallery/":
      return GALLERY;
    case "/about/":
      return ABOUT;
    default:
      return ERROR;
  }
}

function getIcon(path) {
  if (path === "/") {
    return <LangeStudiosLogo />;
    // return <Icon className="lange-studios-logo"></Icon>;
  }
}

const Layout = ({ children, location }) => {
  const path = location.pathname;
  return (
    // <div
    //   style={{
    //     minHeight: "100vh",
    //     display: "flex",

    //     flexDirection: "column",
    //     justifyContent: "space-between",
    //   }}
    // >
    //   <StaticImage
    //     className="background-image fade-image"
    //     src="./../images/exterior-2.jpg"
    //     placeholder="none"
    //     backgroundColor="#51977B"
    //     alt=""
    //     style={{
    //       width: "100px",
    //       height: "100px",
    //     }}
    //   />
    //   {children}
    // </div>
    <div
      style={{
        backgroundColor: getPageInfo(path).backgroundColor,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "grid",
          backgroundColor: getPageInfo(path).mobile,
        }}
      >
        <BackgroundImage />
        <div
          className="page-title-container"
          style={{
            gridArea: "1/1/2/2",
            justifySelf: "center",

            alignContent: "center",
            alignItems: "center",
            width: "100%",

            minHeight: "184px",
            zIndex: 1,
            display: "grid",

            justifyContent: "center",

            backgroundColor: getPageInfo(path).overlay,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="page-title"
          >
            {getPageInfo(path).title}
          </div>
          {getIcon(path)}
        </div>
      </div>
      <Navbar
        className="color-change"
        location={location}
        tabColor={getPageInfo(path).mobile}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>{children}</div>
      </div>
      <div
        style={{
          flexGrow: 1,
          backgroundColor: getPageInfo(path).body,
        }}
      ></div>
      <Footer />
    </div>
  );
};

export default Layout;
